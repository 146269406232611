import gql from 'graphql-tag';

const PACKAGING_USER_FEATURE_ACCESS = gql`
  query PathBuilder_PackagingUserFeatureAccess {
    Packaging_userFeatureAccess {
      userFeatureAccess {
        packageId
        packageName
        features
      }
    }
  }
`;

export { PACKAGING_USER_FEATURE_ACCESS };
