import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsPanel, Header, PathPanel, SearchPanel } from 'components';
import { MainLayout } from 'layouts';
import { trace } from 'services/analytics';
import { SET_PATH_ID, UPDATE_PATH, SET_LOADING_PATH, usePathContext, SET_ERROR_PATH } from 'context/PathContext';
import { RootRouteParams } from './types';
import { NEW_PATH_ID } from 'constant';
import { message } from '@a-cloud-guru/rainbow-ui';
import { getConfig } from 'config/get-config';
import { useGetCustomLearningPath } from 'hooks';
import { bugsnagService } from 'services/analytics/bugsnag';
const Root: React.FC = () => {
  const { pathId } = useParams<RootRouteParams>();

  const {
    state: { isNewPath, loadingPath: loadingPathState, isSaved, path: statePath, selectedItem },
    dispatch
  } = usePathContext();

  const beforeUnloadListener = useCallback((event: BeforeUnloadEvent) => {
    // There is no point in setting any message here
    // because it's not supported by the browsers anymore
    event.returnValue = ' ';
  }, []);

  useEffect(() => {
    if (isSaved) {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    } else {
      window.addEventListener('beforeunload', beforeUnloadListener);
    }
  }, [isSaved, beforeUnloadListener]);

  const { ACG_BUSINESS_URL } = getConfig();

  const redirectToLearningPaths = useCallback(
    () => setTimeout(() => window.location.replace(`${ACG_BUSINESS_URL}/learning-paths`), 3000),
    [ACG_BUSINESS_URL]
  );

  const initialPathId = statePath.id === NEW_PATH_ID ? '' : statePath.id;

  // TODO: Refactor with GQL CodeGen + useLazyQuery!
  const { data: path, loading: loadingPath, error: errorPath } = useGetCustomLearningPath({ id: initialPathId });
  const isReadOnly = path?.isReadOnly;

  useEffect(() => {
    if (!loadingPath) {
      if (errorPath) {
        message.error('Error loading the path. If issues persists contact support.');
        bugsnagService.notifyError(errorPath);
        dispatch({
          type: SET_ERROR_PATH,
          error: errorPath
        });
        redirectToLearningPaths();
      } else if (path === null) {
        message.error('Learning path not found. If issues persists contact support.');
        bugsnagService.notifyError(new Error(`Learning path with ${path?.id} not found`));
        redirectToLearningPaths();
      } else if (path?.isReadOnly) {
        message.error('Path is Read-Only. Read-Only paths cannot be edited.');
        bugsnagService.notifyError(
          new Error(`Read-Only Learning path: ${path?.id}. Redirected to business.acloud.guru`)
        );
        redirectToLearningPaths();
      } else if (path && loadingPathState) {
        dispatch({
          type: UPDATE_PATH,
          path: { ...path, isReadOnly: isReadOnly },
          isNewPath: false
        });
      }
    }
    if (loadingPath) {
      dispatch({ type: SET_LOADING_PATH });
    }
  }, [path, loadingPath, errorPath, isReadOnly, isNewPath, loadingPathState, redirectToLearningPaths, dispatch]);

  // Set the pathId here from the Route - useParams() is not available in PathProvider
  useEffect(() => {
    dispatch({ type: SET_PATH_ID, pathId: pathId });
  }, [pathId, dispatch]);

  useEffect(() => trace.view('Home'), []);

  return (
    <MainLayout
      header={<Header />}
      column1={<PathPanel />}
      column2={<SearchPanel />}
      column3={selectedItem && <DetailsPanel />}
    />
  );
};

export { Root };
