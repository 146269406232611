import { useEffect } from 'react';
import { authService } from 'services/auth';
import { Loading } from 'components/common';

const LoginCallbackPage: React.FC = () => {
  useEffect(() => {
    async function redirect() {
      const { state } = await authService.loginCallback();

      // AuthService package we are using type-hints this as `object` which is no longer recommended and doesn't work for dynamic object keys.
      // Therefore, we are forcing the type of this to be a Record<'redirect_to', string>
      const decodedState = authService.decodeState(state) as Record<'redirect_to', string> | undefined;
      const authRedirectToUrl = decodedState?.redirect_to || '/path-builder';

      window.location.replace(authRedirectToUrl);
    }

    redirect();
  }, []);

  return <Loading />;
};

export { LoginCallbackPage };
