import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button, Box, Input, Divider, colours, message } from '@a-cloud-guru/rainbow-ui';
import TextArea from 'antd/lib/input/TextArea';
import { getConfig } from 'config/get-config';
import { UPDATE_PUBLISHED_AT, UPDATE_METADATA, usePathContext } from 'context/PathContext';
import { CharacterLimitText, getRemainingCharactersAndCaption } from 'components/common/CharacterLimitText';
import {
  MAX_PATH_NAME_LENGTH,
  PATH_NAME_WARNING_LIMIT,
  MAX_PATH_DESCRIPTION_LENGTH,
  PATH_DESCRIPTION_WARNING_LIMIT
} from 'constant';
import { usePublishPath, useSavePath } from 'hooks';

const { ACG_BUSINESS_URL } = getConfig();

const pathPublishError = 'Path not published. Check connection and try again.';

interface PublishModalProps {
  isPublishModalVisible: boolean;
  hidePublishModal: () => void;
  learningPathId: string;
  onPublishAndAddUsers: () => void;
}

export const PublishModal: React.FC<PublishModalProps> = ({
  isPublishModalVisible,
  hidePublishModal,
  learningPathId,
  onPublishAndAddUsers
}) => {
  const {
    state: { path },
    dispatch
  } = usePathContext();
  const initialPathTitle = path.title;
  const initialPathDescription = path.description;

  const [pathTitle, setPathTitle] = useState<string>(initialPathTitle);
  const [pathDescription, setPathDescription] = useState<string | undefined>(initialPathDescription);

  const [isPathTitleError, setIsPathTitleError] = useState<boolean>(false);
  const pathTitleInputRef = useRef<Input>(null);
  const pathDescriptionTextAreaRef = useRef<TextArea>(null);
  const [pathTitleErrorMessage, setPathTitleErrorMessage] = useState<string | null>(null);

  const [publishButtonLoading, setPublishButtonLoading] = useState(false);
  const [publishAndAddUsersButtonLoading, setPublishAndAddUsersButtonLoading] = useState(false);

  const { savePath } = useSavePath();
  const publishPath = usePublishPath();

  const pathTitleLength = pathTitle?.length || 0;
  const pathDescriptionLength = pathDescription?.length || 0;
  const isPathTitleOverLimit = pathTitleLength > MAX_PATH_NAME_LENGTH;
  const isPathDescriptionOverLimit = pathDescriptionLength > MAX_PATH_DESCRIPTION_LENGTH;

  useEffect(() => {
    if (isPublishModalVisible) {
      return;
    }
    const pathTitleChanged = initialPathTitle !== pathTitle;
    if (pathTitleChanged) {
      setPathTitle(initialPathTitle);
    }
    const pathDescriptionChanged = initialPathDescription !== pathDescription;
    if (pathDescriptionChanged) {
      setPathDescription(initialPathDescription);
    }
    setIsPathTitleError(false);
  }, [isPublishModalVisible, initialPathTitle, pathTitle, initialPathDescription, pathDescription]);

  const focusOnFirstInputError = ({
    isPathTitleInvalid,
    isPathDescriptionInvalid
  }: {
    isPathTitleInvalid: boolean;
    isPathDescriptionInvalid: boolean;
  }) => {
    if (isPathTitleInvalid && pathTitleInputRef.current) {
      pathTitleInputRef.current.focus();
      return;
    }
    if (isPathDescriptionInvalid && pathDescriptionTextAreaRef.current) {
      pathDescriptionTextAreaRef.current.focus();
    }
  };

  const getPathTitleError = (): string | null => {
    if (isPathTitleOverLimit === true) {
      // Disable eslint because of unused variable _remainingCharacters
      // eslint-disable-next-line
      const [_remainingCharacters, caption] = getRemainingCharactersAndCaption({
        maxLength: MAX_PATH_NAME_LENGTH,
        length: pathTitleLength,
        isOverLimit: isPathTitleOverLimit,
        textEntityType: 'Name'
      });
      return caption;
    }
    if (pathTitleLength === 0) {
      return 'Path must have a name';
    }
    const isPathTitleUntitledPath = pathTitle?.trim().toLocaleLowerCase() === 'untitled path';
    if (isPathTitleUntitledPath) {
      return `Path name cannot be ${pathTitle}`;
    }
    return null;
  };

  const getIsPathTitleInvalid = (pathTitleErrorMessage: string | null): boolean => {
    return pathTitleErrorMessage !== null;
  };

  const getIsPathDescriptionInvalid = (): boolean => {
    return isPathDescriptionOverLimit === true;
  };

  const validatePathTitleAndDescription = () => {
    const pathTitleErrorMessage = getPathTitleError();
    const isPathTitleInvalid = getIsPathTitleInvalid(pathTitleErrorMessage);
    const isPathDescriptionInvalid = getIsPathDescriptionInvalid();
    setPathTitleErrorMessage(pathTitleErrorMessage);
    setIsPathTitleError(isPathTitleInvalid);
    focusOnFirstInputError({ isPathTitleInvalid, isPathDescriptionInvalid });
    if (isPathTitleInvalid || isPathDescriptionInvalid) {
      return false;
    }
    return true;
  };

  const publishCustomLearningPath = async () => {
    const pathTitleChanged = initialPathTitle !== pathTitle;
    const name = pathTitleChanged ? pathTitle : undefined;
    const pathDescriptionChanged = initialPathDescription !== pathDescription;
    const description = pathDescriptionChanged ? pathDescription : undefined;
    if (pathTitleChanged || pathDescriptionChanged) {
      await savePath(name, description);
    }
    await publishPath(learningPathId).then((path) => {
      if (path && path.data) {
        dispatch({
          type: UPDATE_PUBLISHED_AT,
          publishedAt: path.data.customLearningPathPublish.publishedAt
        });
      }
    });
  };

  const publish = async () => {
    const isValid = validatePathTitleAndDescription();
    if (isValid) {
      try {
        setPublishButtonLoading(true);
        await publishCustomLearningPath();
        window.location.assign(`${ACG_BUSINESS_URL}/learning-path?id=${learningPathId}`);
      } catch {
        message.error(pathPublishError);
      }
      setPublishButtonLoading(false);
    }
  };

  const publishAndAddUsers = async () => {
    const isValid = validatePathTitleAndDescription();
    if (isValid) {
      try {
        setPublishAndAddUsersButtonLoading(true);
        dispatch({
          type: UPDATE_METADATA,
          title: pathTitle,
          description: pathDescription,
          isSaved: true
        });
        await publishCustomLearningPath();
        hidePublishModal();
        onPublishAndAddUsers();
      } catch {
        message.error(pathPublishError);
      }
      setPublishAndAddUsersButtonLoading(false);
    }
  };

  const publishButton = (
    <Button key={'publishButton'} onClick={publish} loading={publishButtonLoading}>
      Publish
    </Button>
  );
  const publishAndAddUsersButton = (
    <Button
      key={'publishAndAddUsersButton'}
      type="primary"
      onClick={publishAndAddUsers}
      loading={publishAndAddUsersButtonLoading}
    >
      Publish and add users
    </Button>
  );
  return (
    <StyledModal
      title="Double-check the details before publishing"
      visible={isPublishModalVisible}
      onCancel={hidePublishModal}
      footer={[publishButton, publishAndAddUsersButton]}
    >
      <PublishModalDescription>After publishing this path will be available to all users.</PublishModalDescription>
      <PathTitleInputHeading>Path Name</PathTitleInputHeading>
      <PathTitleInput
        data-testid="modal-path-title"
        ref={pathTitleInputRef}
        placeholder="Add path name..."
        value={pathTitle}
        onChange={(e) => {
          const input = e.target.value;
          setPathTitle(input);
          setIsPathTitleError(false);
        }}
      />
      {isPathTitleError ? (
        <PathTitleErrorMessage>{pathTitleErrorMessage}</PathTitleErrorMessage>
      ) : (
        <CharacterLimitText
          textEntityType="Name"
          maxLength={MAX_PATH_NAME_LENGTH}
          length={pathTitleLength}
          warningLimit={PATH_NAME_WARNING_LIMIT}
          isOverLimit={isPathTitleOverLimit}
        />
      )}
      <ModalBodyDivider />
      <PathTitleInputHeading>Path Description (optional)</PathTitleInputHeading>
      <DescriptionTextArea
        ref={pathDescriptionTextAreaRef}
        rows={10}
        value={pathDescription}
        onChange={(e) => {
          const input = e.target.value;
          setPathDescription(input);
        }}
      />

      <DescriptionBottomText>
        <Box>Help learners understand who this path is for and what the learning outcomes are.</Box>
      </DescriptionBottomText>
      <CharacterLimitText
        textEntityType="Description"
        maxLength={MAX_PATH_DESCRIPTION_LENGTH}
        length={pathDescriptionLength}
        warningLimit={PATH_DESCRIPTION_WARNING_LIMIT}
        isOverLimit={isPathDescriptionOverLimit}
      />
      <ModalBodyDivider />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 595px;
    border-radius: 3px;
    padding: 20px 10px;
  }
  .ant-modal-body {
    padding: 0 24px 30px;
  }
  .ant-modal-header {
    padding: 16px 24px 10px 24px;
    border-bottom-width: 0;
  }
  .ant-modal-footer {
    border-top-width: 0;
  }
  .ant-modal-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 21px;
    color: ${colours.navy900};
  }
  .ant-modal-close {
    top: 20px;
    right: 10px;
  }
  .ant-modal-close-icon {
    color: ${colours.darkGrey700};
  }
  color: ${colours.darkGrey800};
`;

const PublishModalDescription = styled(Box)`
  margin-bottom: 24px;
  color: ${colours.darkGrey900};
`;

const PathTitleInputHeading = styled(Box)`
  font-weight: 600;
  font-size: 14px;
`;

const PathTitleInput = styled(Input)`
  margin: 6px 0;
  border: 1px solid ${colours.darkGrey100};
  border-radius: 3px;
`;

const PathTitleErrorMessage = styled(Box)`
  font-weight: 600;
  font-size: 12px;
  color: ${colours.red900};
`;

const ModalBodyDivider = styled(Divider)`
  border: 1px solid ${colours.lightGrey700};
`;

const DescriptionTextArea = styled(TextArea)`
  margin: 6px 0;
  resize: none;
  border: 1px solid ${colours.darkGrey100};
  border-radius: 3px;
`;

const DescriptionBottomText = styled(Box)`
  margin-top: 10px;
  font-size: 12px;
`;
