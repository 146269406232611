import gql from 'graphql-tag';

const LAB_QUERY = gql`
  query PathBuilder_lab($labId: String!) {
    Labs_lab(labId: $labId) {
      type
      categories {
        name
      }
      difficulty
      averageCompletionTime
      description
      tasks {
        id
        name
        explanation
        disabled
      }
    }
  }
`;

export { LAB_QUERY };
