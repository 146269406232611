import { usePathContext } from 'context/PathContext';

const useTotalDuration = (): number => {
  const {
    state: { path, errorPath, loadingPath }
  } = usePathContext();

  if (errorPath || loadingPath) return 0;

  const totalDuration = path?.sections.reduce(
    (total, section) =>
      total +
      section.components.reduce((totalSection, component) => totalSection + Number(component.computedDuration), 0),
    0
  );

  return totalDuration;
};

export { useTotalDuration };
