import React from 'react';
import styled from 'styled-components';
import { colours, Flexbox, Box } from '@a-cloud-guru/rainbow-ui';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Labs_Lab } from 'types';
import { MetadataTile, Skeleton, SKILL_LEVEL_ICONS } from 'components/common';
import { PROVIDER_LOOKUP } from '../../constant';
import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';

const MetadataSkeletons = () => (
  <MetaDataList>
    <Skeleton mr="s3" />
    <Skeleton width="100px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
  </MetaDataList>
);

interface LabMetaDataProps {
  loading: boolean;
  lab: Labs_Lab;
}

const LabMetaData: React.FC<LabMetaDataProps> = ({ loading, lab }) => {
  const { type, categories, averageCompletionTime, description, difficulty } = lab;
  const typeProvider = PROVIDER_LOOKUP[type?.toLowerCase()];
  const categoryProviderLookups = categories?.map((category) => PROVIDER_LOOKUP[category?.name?.toLowerCase()]) || [];
  const categoryProvider = categoryProviderLookups.find((category) => category);
  const provider = typeProvider ? typeProvider : categoryProvider;

  const MetadataTiles = () => (
    <MetaDataList>
      {provider && (
        <MetaDataListItem iconComponent={<ProviderSvg />} description="Provider">
          {provider}
        </MetaDataListItem>
      )}
      {difficulty && (
        <MetaDataListItem iconComponent={SKILL_LEVEL_ICONS[difficulty.toLowerCase()]} description="Skill level">
          <StyledDescription>{difficulty}</StyledDescription>
        </MetaDataListItem>
      )}
      {averageCompletionTime && (
        <MetaDataListItem iconComponent={<DurationIcon />} description="Average completion time">
          {averageCompletionTime}
        </MetaDataListItem>
      )}
    </MetaDataList>
  );

  return (
    <MetaDataContainer>
      {loading ? <Skeleton height="36px" width="140px" bgColor={colours.darkGrey400} /> : null}
      {loading ? <MetadataSkeletons /> : <MetadataTiles />}
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} mb="s3" />
          <Skeleton width="30%" bgColor={colours.darkGrey400} mb="s4" />
        </>
      ) : (
        <SummaryText linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {description}
        </SummaryText>
      )}
    </MetaDataContainer>
  );
};

const MetaDataContainer = styled(Box)`
  padding-bottom: 6px;
`;

const StyledDescription = styled.span`
  text-transform: capitalize;
`;

const MetaDataList = styled(Flexbox)`
  align-items: center;
  color: ${colours.navy900};
  padding: ${({ theme }) => theme.space.s5} 0;
`;

const MetaDataListItem = styled(MetadataTile)`
  color: ${colours.navy900};
`;

const SummaryText = styled(ReactMarkdown)`
  font-size: 14px;
  line-height: 21px;
  color: ${colours.navy900};
`;

export { LabMetaData };
