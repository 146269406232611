import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
import {
  Configure,
  InstantSearch,
  connectInfiniteHits,
  connectSearchBox,
  connectStateResults,
  connectRefinementList
} from 'react-instantsearch-dom';
import { Flexbox, Box } from '@a-cloud-guru/rainbow-ui';

import { getConfig } from 'config';
import { ScrollableArea } from 'components/common/';
import { SearchResults } from './SearchResults';
import { SearchHeader } from './SearchHeader';
import { QuickSearch } from './QuickSearch';
import { RefinementList } from './RefinementList';
import { TotalSearchResults } from './TotalSearchResults';
import { useSplit } from 'hooks';

const { ALGOLIA_APP_ID, ALGOLIA_SEARCH_ONLY_API_KEY, GLOBAL_SEARCH_ALGOLIA_INDEX } = getConfig();
const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_ONLY_API_KEY);

const Results = connectStateResults(SearchResults);
const CustomSearchHeader = connectSearchBox(connectStateResults(SearchHeader));
const CustomTotalSearchResults = connectSearchBox(connectStateResults(TotalSearchResults));
const CustomQuickSearch = connectSearchBox(QuickSearch);
const CustomRefinementList = connectRefinementList(RefinementList);

const SearchPanel: React.FC = () => {
  // split flag for standalone labs
  const standaloneLabsSplit = useSplit('CLP_STANDALONE_LABS');
  const filters = `learningTypes:course${standaloneLabsSplit ? ' OR learningTypes:acg_hands_on_lab' : ''}`;
  return (
    <InstantSearch indexName={GLOBAL_SEARCH_ALGOLIA_INDEX} searchClient={searchClient}>
      <Configure filters={filters} hitsPerPage={30} />
      <PanelContainer>
        <SearchPanelInterface />
      </PanelContainer>
    </InstantSearch>
  );
};

const SearchPanelInterface = connectInfiniteHits(({ hits = [], hasMore, refineNext }) => {
  const standaloneLabsSplit = useSplit('CLP_STANDALONE_LABS');
  return (
    <>
      <CustomSearchHeader />
      {standaloneLabsSplit && <CustomRefinementList attribute="learningTypes" searchable={true} />}
      <CustomTotalSearchResults hits={hits} />
      <CustomQuickSearch hits={hits} />
      <ScrollableArea>
        <ResultsContainer>
          <Results hits={hits} hasMore={hasMore} refineNext={refineNext} />
        </ResultsContainer>
      </ScrollableArea>
    </>
  );
});

const ResultsContainer = styled(Box)`
  margin-right: ${({ theme }) => theme.space.s3};
`;

const PanelContainer = styled(Flexbox)`
  flex: 1;
  flex-direction: column;
`;

export { SearchPanel };
