import { useEffect } from 'react';
import styled from 'styled-components';
import { Box, colours, message } from '@a-cloud-guru/rainbow-ui';

import { getConfig } from 'config';
import { useGetLab } from 'hooks';
import { RulerLine } from 'components/common';
import { DetailsContainer } from './DetailsContainer';
import { LabMastHead } from './LabMastHead';
import { LabMetaData } from './LabMetaData';
import { ReactComponent as ExternalLinkSvg } from 'static/images/svg-icons/external-link.svg';

const { ACG_LEARN_URL } = getConfig();
const errorMessage = 'Failed to fetch hands-on lab details. Check your connection and try again.';

interface LabDetailsProps {
  labId?: string;
}
const LabDetails: React.FC<LabDetailsProps> = ({ labId }) => {
  const { lab, error, loading } = useGetLab({ labId });

  useEffect(() => {
    if (error) {
      message.error(errorMessage);
    }
  }, [error]);
  const labLandingUrl = `${ACG_LEARN_URL}/handson/${labId}`;

  return (
    <>
      <LabMastHead />
      <DetailsContainer>
        {!labId || error ? (
          <div>{errorMessage}</div>
        ) : (
          <>
            <LabMetaData loading={loading} lab={lab} />
            <RulerLine colour={colours.navy200} />
            <ExternalLinkContainer>
              <ExternalLink href={labLandingUrl} target="_blank" rel="noreferrer">
                <ExternalLinkText>View Lab</ExternalLinkText>
                <ExternalLinkIcon role="img" />
              </ExternalLink>
            </ExternalLinkContainer>
          </>
        )}
      </DetailsContainer>
    </>
  );
};

const ExternalLinkContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => `${theme.space.s5} 0`};
`;

const ExternalLink = styled.a`
  color: ${colours.navy900};
  line-height: 20px;
  font-size: 14px;
  :hover {
    text-decoration: underline;
    color: inherit;
  }
`;

const ExternalLinkText = styled.span`
  margin-right: ${({ theme }) => theme.space.s1};
`;

const ExternalLinkIcon = styled(ExternalLinkSvg)`
  height: 16px;
  width: 16px;
  path {
    fill: ${colours.darkGrey400};
  }
`;
export { LabDetails };
