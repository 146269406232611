import { useMemo } from 'react';

import { usePathContext } from 'context/PathContext';
import { Component } from 'types/learning-path/Component';

const getCourseId = (component: Component): string => {
  const { type, courseId = '', id } = component;
  return type === 'clp_course_extract' ? courseId : id;
};

const useIsComponentIdInPath = (): ((id: string) => boolean) => {
  const {
    state: { path }
  } = usePathContext();

  const pathIds = useMemo(() => {
    const ids = new Set();
    path?.sections.forEach((section) =>
      section?.components.forEach((component) => {
        const courseId = getCourseId(component);
        ids.add(courseId);
      })
    );
    return ids;
  }, [path]);

  return (id: string) => pathIds.has(id);
};

export { useIsComponentIdInPath, getCourseId };
