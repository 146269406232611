import { useRef } from 'react';
import styled from 'styled-components';
import { colours, Box, Input } from '@a-cloud-guru/rainbow-ui';
import { SearchBoxProvided } from 'react-instantsearch-core';

import { ReactComponent as SearchIcon } from 'static/images/svg-icons/search.svg';
import { ReactComponent as CloseIcon } from 'static/images/svg-icons/close-edge.svg';
import { StyledSVG } from 'components/common';
import { useSearchQueryContext } from 'context/SearchQueryContext';
import { useSplit } from 'hooks';
import { TemporaryBannerCLP } from './TemporaryBanner/TemporaryBanner';
import { SET_SELECTED_ITEM, usePathContext } from 'context/PathContext';

interface SearchHeaderProps extends Pick<SearchBoxProvided, 'refine'> {
  placeholder?: string;
  delay?: number;
  searching: boolean;
}

const SearchHeader: React.FC<SearchHeaderProps> = ({
  refine,
  placeholder = 'Search for courses',
  delay = 500,
  searching
}) => {
  const searchInput = useRef<Input>(null);
  const timerId = useRef<number>();
  const { searchQuery, setSearchQuery } = useSearchQueryContext();
  const { dispatch } = usePathContext();
  const showCLPBanner = useSplit('CLP_BANNER');
  const standaloneLabsSplit = useSplit('CLP_STANDALONE_LABS');

  const debouncedSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.currentTarget.value;

    clearTimeout(timerId.current);
    timerId.current = window.setTimeout(() => refine(eventValue), delay);

    if (eventValue === '') {
      dispatch({ type: SET_SELECTED_ITEM, newItem: null });
    }

    setSearchQuery(eventValue);
  };

  const disableEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const clearSearch = () => {
    dispatch({ type: SET_SELECTED_ITEM, newItem: null });
    setSearchQuery('');
    refine(null);
    searchInput?.current?.focus();
  };

  return (
    <Box data-cy="pb-search-header-input">
      {showCLPBanner && <TemporaryBannerCLP />}
      <form role="search">
        <StyledInput
          ref={searchInput}
          type="search"
          value={searchQuery}
          placeholder={standaloneLabsSplit ? 'Search for content' : placeholder}
          onChange={debouncedSearch}
          onPressEnter={disableEnter}
          suffix={
            <SearchHeaderIcon searching={searching} isEmpty={searchQuery.length === 0} clearSearch={clearSearch} />
          }
        />
      </form>
    </Box>
  );
};

interface SearchHeaderIconProps {
  searching: boolean;
  isEmpty: boolean;
  clearSearch: () => void;
}

const SearchHeaderIcon: React.FC<SearchHeaderIconProps> = ({ isEmpty, clearSearch }) => {
  if (isEmpty) return <StyledSVG svgComponent={<SearchIcon />} />;
  return <StyledSVG svgComponent={<CloseIcon />} onClick={clearSearch} css={'cursor: pointer;'} />;
};

export { SearchHeader };

const StyledInput = styled(Input)`
  input {
    border-radius: ${({ theme }) => theme.radii.sm};
    height: ${({ theme }) => theme.space.s9};

    :focus,
    :hover {
      border: 1px solid ${colours.blue};
      box-shadow: inset 0px 2px 1px rgba(3, 0, 30, 0.05);
    }
  }

  input ::placeholder {
    color: ${colours.darkGrey300};
  }
`;
