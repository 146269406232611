'use-strict';
/* eslint @typescript-eslint/no-var-requires: "off" */

import merge from 'lodash.merge';

import { schema } from './schema';

interface Environment {
  ACG_ENV: string;
  ACG_DOMAIN?: string;
  ACG_LEARN_URL?: string;
  ACG_BUSINESS_URL: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_SEARCH_ONLY_API_KEY: string;
  GLOBAL_SEARCH_ALGOLIA_INDEX: string;
  ORG_USERS_ALGOLIA_INDEX: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN?: string;
  AUTH0_CUSTOM_DOMAIN?: string;
  AUTH0_API_AUDIENCE?: string;
  GRAPHQL_API_ENDPOINT: string;
  CLOUDINARY_ACCOUNT: string;
  CLOUDINARY_BASE_URL?: string;
  SPLIT_IO_API_KEY: string;
  VERSION?: string;
  BUGSNAG_API_KEY: string;
  RELEASE_STAGE: string;
  PRINT_TRACE_LOGS?: boolean;
  SEGMENT_API_KEY?: string;
  BUILD_NUMBER?: number;
  COMMIT_HASH?: string;
}
const getConfigForEnvironment = (environment: string): Environment => {
  try {
    const commonConfig = require('./common.config');
    const environmentConfig = require(`./${environment}.config`);
    const config = merge(commonConfig, environmentConfig);

    const { error, value } = schema.validate(config.default);

    if (error) {
      throw error;
    }

    return value;
  } catch (e) {
    // Print any errors when config file is missing
    console.error(e);
    throw e;
  }
};

export type { Environment };
export { getConfigForEnvironment };
