export default {
  ACG_DOMAIN: 'https://acloud.guru',
  ACG_LEARN_URL: 'https://learn.acloud.guru',
  ACG_BUSINESS_URL: 'https://business.acloud.guru',
  ALGOLIA_SEARCH_ONLY_API_KEY: 'bd07683ba0f90f58b2ea5def1ec0cc27',
  GLOBAL_SEARCH_ALGOLIA_INDEX: 'prod_global_search',
  ORG_USERS_ALGOLIA_INDEX: 'private_prod_all_organisation_users',
  AUTH0_CLIENT_ID: 'tkySw0ERfyx54uXVN2lIEGEaLTWU2gqC',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  RELEASE_STAGE: 'production',
  SPLIT_IO_API_KEY: 'u28vabgv0jhqedmpunitvia0eg7s3f2gou3l',
  PRINT_TRACE_LOGS: false,
  SEGMENT_API_KEY: 'uJOZJcGwyy9mCv2Npxr8WY7fDHWW3obR'
};
