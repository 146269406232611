import { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Flexbox, colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  DELETE_ITEM_FROM_PATH,
  POST_ADD_ITEM_TO_PATH,
  SET_PATH_IS_SAVED,
  REORDER_ITEMS,
  usePathContext,
  SET_SELECTED_ITEM
} from 'context/PathContext';
import { ScrollableArea } from 'components/common';
import { makeBuildOptimizedSrc } from 'components/common/image';
import { CustomLearningPath, Component } from 'types';
import EmptyComponentTransparent from 'static/images/path-content-empty-component-transparent.png';
import { PathComponent } from './PathComponent';
import { usePalette } from 'hooks';
import { ARTWORK_SOURCE_WIDTH } from 'constant';
import { get } from 'lodash';
import { trace } from 'services/analytics';

const useSections = () => {
  const {
    state: { path, errorPath, loadingPath, isReadOnly }
  } = usePathContext();
  const { sections } = path || {};

  // TODO: when error, show a toast with error message
  const message = errorPath ? 'Error loading path. Check your internet connection and refresh the page' : undefined;

  return { message, sections, loadingPath, isReadOnly };
};

const EmptyState = ({ message = 'Add courses to get started' }) => (
  <EmptyStateMessage justifyContent="center" alignItems="center">
    {message}
  </EmptyStateMessage>
);

const LoadingState = () => (
  <Flexbox alignItems="flex-start" flexDirection="column">
    {[...new Array(2)].map((_, index) => (
      <EmptyComponentContainer key={index} width={1} bg={'#FEFEFE'} mb="s4">
        <img src={EmptyComponentTransparent} alt="Course Placeholder" />
      </EmptyComponentContainer>
    ))}
  </Flexbox>
);

interface DraggablePathComponentProps {
  component: Component;
  index: number;
  deleteItemFromPath: (componentId: string) => void;
}

const DraggablePathComponent: React.FC<DraggablePathComponentProps> = ({ component, index, deleteItemFromPath }) => {
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();
  const isItemSelected = selectedItem?.itemId === component.id;
  const optimisedArtworkUrl = makeBuildOptimizedSrc({ width: ARTWORK_SOURCE_WIDTH, format: 'auto' })(
    component.artworkUrl
  );
  const { palette } = usePalette(optimisedArtworkUrl);
  const bgColor = get(palette, 'lightVibrant', colours.white);
  return (
    <Draggable draggableId={component.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <PathComponent
            index={index}
            component={component}
            artworkUrl={optimisedArtworkUrl}
            bgColor={bgColor}
            deleteItemFromPath={() => deleteItemFromPath(component.id)}
            isDragging={snapshot.isDragging}
            isItemSelected={isItemSelected}
            onClick={() =>
              dispatch({
                type: SET_SELECTED_ITEM,
                newItem: {
                  itemId: component.id,
                  courseId:
                    component.courseId && component.type === 'clp_course_extract' ? component.courseId : component.id,
                  title: component.title,
                  itemType: component.type,
                  isInPath: true
                }
              })
            }
          />
        </div>
      )}
    </Draggable>
  );
};
type Sections = CustomLearningPath['sections'];
interface PathComponentsListProps {
  sections: Sections;
}
const PathComponentsList: React.FC<PathComponentsListProps> = ({ sections }) => {
  const {
    state: {
      path: { id: pathId }
    },
    dispatch
  } = usePathContext();
  const deleteItemFromPath = (componentId: string) => {
    trace.track('Item removed from Path', { pathId, courseId: componentId });

    dispatch({
      type: DELETE_ITEM_FROM_PATH,
      componentId: componentId,
      isSaved: false
    });
  };
  return (
    <>
      {sections.map(({ components }) =>
        components.map((component, index) => (
          <DraggablePathComponent
            data-cy={`pb-path-component-${index}`}
            key={`${component.courseId}-${index}`}
            component={component}
            index={index}
            deleteItemFromPath={() => deleteItemFromPath(component.id)}
          />
        ))
      )}
    </>
  );
};

interface RenderPathBodyProps {
  loadingPath: boolean;
  components: Array<Component>;
  message?: string;
  sections: Sections;
}
const renderPathBody: React.FC<RenderPathBodyProps> = ({ loadingPath, components, message, sections }) => {
  if (loadingPath) return <LoadingState />;
  if (isEmpty(components)) return <EmptyState message={message} />;
  return <PathComponentsList sections={sections} />;
};

const PathContent: React.FC = () => {
  const {
    state: { isScrollingPathContentToEnd }
  } = usePathContext();

  const { sections, message, loadingPath, isReadOnly } = useSections();
  const components = sections?.[0]?.components;
  const { dispatch } = usePathContext();
  const scrollableAreaEnd = useRef<typeof Box>();

  useEffect(() => {
    if (isScrollingPathContentToEnd) {
      scrollableAreaEnd?.current?.scrollIntoView({ behavior: 'smooth' });
      dispatch({
        type: POST_ADD_ITEM_TO_PATH
      });
    }
  }, [isScrollingPathContentToEnd, dispatch]);

  const handleDragging = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) return;

    dispatch({
      type: SET_PATH_IS_SAVED,
      isSaved: false
    });

    dispatch({
      type: REORDER_ITEMS,
      startIndex: source.index,
      endIndex: destination.index
    });
  };

  if (isReadOnly) {
    return null;
  }

  return (
    <ScrollableArea>
      <DragDropContext onDragEnd={handleDragging}>
        <Droppable droppableId="path-content">
          {(provided, snapshot) => (
            <DroppableArea
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {renderPathBody({ loadingPath, components, message, sections })}
              {provided.placeholder}
            </DroppableArea>
          )}
        </Droppable>
      </DragDropContext>
      <Box ref={scrollableAreaEnd} />
    </ScrollableArea>
  );
};

interface DroppableAreaProps {
  isDraggingOver: boolean;
}
const DroppableArea = styled.div<DroppableAreaProps>`
  background: ${({ isDraggingOver }) => (isDraggingOver ? '#E8EFFF' : 'none')};
  border-radius: 4px;
`;

const EmptyStateMessage = styled(Flexbox)`
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space.s2};
`;

const EmptyComponentContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.s2};
  width: 100%;
  border: 1px solid #d7e0f1;
  border-radius: 4px;
`;

export { PathContent };
