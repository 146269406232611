import styled from 'styled-components';

import { Box, Flexbox, colours, easing } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as SearchLabsIcon } from 'static/images/svg-icons/search-labs.svg';
import { StyledSVG } from 'components/common';
import { Heading3, Heading4 } from 'components/typography';
import { makeBuildOptimizedSrc } from 'components/common/image';
import { Metadata, MetadataHit } from './Metadata';
import { SET_SELECTED_ITEM, usePathContext } from 'context/PathContext';
import { useIsComponentIdInPath } from 'hooks';
import { ALGOLIA_SEARCH_HIT_TYPE, ARTWORK_SOURCE_WIDTH } from 'constant';
import { usePalette } from 'hooks';
import { rgba } from 'polished';

interface SearchResultProps {
  index: number;
  isInPath: boolean;
  hit: MetadataHit;
}

const SearchResult: React.FC<SearchResultProps> = ({ hit, index, isInPath }) => {
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();

  const isIdComponentIdInPath = useIsComponentIdInPath();
  const isCurrentlySelectedItem = selectedItem?.courseId === hit.objectID;

  const optimisedArtworkUrl = makeBuildOptimizedSrc({ width: ARTWORK_SOURCE_WIDTH, format: 'auto' })(hit.imageUrl);
  const { palette } = usePalette(optimisedArtworkUrl);
  const bgColor = palette?.vibrant ?? colours.indigo300;
  const isCourse = hit.type === 'COURSE';
  return (
    <ResultContainer
      tabIndex="0"
      className={isCurrentlySelectedItem ? 'selected' : ''}
      data-cy={`pb-search-result-${index}`}
    >
      <CourseData
        onClick={() =>
          dispatch({
            type: SET_SELECTED_ITEM,
            newItem: {
              itemId: hit.objectID,
              courseId: isCourse ? hit.objectID : '',
              title: hit.title,
              itemType: hit.type,
              isInPath: isIdComponentIdInPath(hit.objectID)
            }
          })
        }
      >
        <ArtworkContainer>
          {isCourse && (
            <>
              <ArtworkBackground isSolid={false} bgColor={bgColor} />
              <Artwork src={optimisedArtworkUrl} alt={hit.title} />
            </>
          )}
          {hit.type === 'ACG_HANDS_ON_LAB' && (
            <>
              <ArtworkBackground isSolid={true} bgColor={colours.lightGrey300} />
              <StyledIcon svgComponent={<SearchLabsIcon />} size="60px" />
            </>
          )}
        </ArtworkContainer>
        <DataContainer flexGrow={1}>
          <Box>
            <ResultType>{ALGOLIA_SEARCH_HIT_TYPE[hit.type] || hit.type}</ResultType>
            <Title>{hit.title}</Title>
          </Box>
          <Metadata hit={hit} />
        </DataContainer>
      </CourseData>
      <Flexbox justifyContent="flex-end" paddingTop={10} paddingRight={10}>
        {isInPath && <InPathTagStyled />}
      </Flexbox>
    </ResultContainer>
  );
};

const InPathTagStyled = () => <GreenTag>IN PATH</GreenTag>;

const GreenTag = styled.div`
  display: flex;
  padding: 0px 4px;
  height: 1em;
  padding: 10px 5px;
  margin-top: 5px;
  margin-right: 5px;
  color: ${colours.navy900};
  font-weight: bold;
  font-size: 11px;
  align-items: center;
  white-space: nowrap;
  background: ${colours.green100};
  border: 1px solid ${colours.green900};
  box-sizing: border-box;
  border-radius: 6px;
`;

const CourseData = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  padding: 20px 0 10px;
`;

const ResultContainer = styled(Flexbox)`
  transition: 0.2s ${easing.easeInOutCubic};
  outline: none;

  :focus {
    border: 1px dotted ${colours.black};
    border-radius: 2px;
  }

  :hover {
    background-color: ${colours.blue50};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${colours.lightGrey500};
  }

  &.selected {
    border: none;
    background-color: ${colours.blue100};
  }
`;

const DataContainer = styled(Flexbox)`
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`;

const ArtworkContainer = styled(Box)`
  position: relative;
  margin-right: 10px;
`;
interface artworkBackgroundProps {
  bgColor: string;
  isSolid: boolean;
}
const ArtworkBackground = styled.div<artworkBackgroundProps>`
  position: static;
  width: 177.78px;
  height: 100px;
  left: 0px;
  top: 0px;
  background-color: ${({ bgColor, isSolid }) => (bgColor && isSolid ? bgColor : rgba(bgColor, 0.15))};
  flex: none;
  order: 0;
  flex-grow: 0;
`;
const Artwork = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  left: 49px;
  top: 10px;
  filter: drop-shadow(0 2px 4px rgb(0, 0, 0, 0.08));
  border-radius: 2px;
`;

const StyledIcon = styled(StyledSVG)`
  position: absolute;
  left: 59px;
  top: 20px;
`;

const ResultType = styled(Heading4)`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: ${colours.indigo500};
`;

const Title = styled(Heading3)`
  font-family: 'GT Walsheim';
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: ${colours.navy900};
`;

export { SearchResult };
