import { ReactComponent as NoviceIcon } from 'static/images/svg-icons/novice.svg';
import { ReactComponent as ApprenticeIcon } from 'static/images/svg-icons/apprentice.svg';
import { ReactComponent as PractitionerIcon } from 'static/images/svg-icons/practitioner.svg';
import { ReactComponent as ProfessionalIcon } from 'static/images/svg-icons/professional.svg';

//Course service provides a different set of skill levels to Algolia. We want to keep it consistent with what Algolia returns instead
const SKILL_LEVEL_MAP: { [key: string]: string } = {
  intro: 'novice',
  beginner: 'apprentice',
  intermediate: 'practitioner',
  advanced: 'professional'
};

const SKILL_LEVEL_ICONS: { [key: string]: JSX.Element } = {
  novice: <NoviceIcon />,
  apprentice: <ApprenticeIcon />,
  practitioner: <PractitionerIcon />,
  professional: <ProfessionalIcon />
};

export { SKILL_LEVEL_MAP, SKILL_LEVEL_ICONS };
