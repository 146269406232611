import { useQuery } from '@apollo/react-hooks';

import { USER_PROFILE_QUERY } from 'data';

// TODO: Refactor with GQL CodeGen
const useProfile = (): any => {
  const { data, loading, error } = useQuery(USER_PROFILE_QUERY);

  return {
    userIdentity: data?.userIdentity,
    organisation: data?.organisation,
    loading,
    error
  };
};

export { useProfile, USER_PROFILE_QUERY };
