import React from 'react';
import { Button, colours, Icon, message } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

import { usePathContext } from 'context/PathContext';
import { useSavePath, useSaveNewPath } from 'hooks';

import { ReactComponent as CheckIcon } from 'static/images/svg-icons/check.svg';

const SaveButton: React.FC = () => {
  const {
    state: { isSaved, path, isNewPath }
  } = usePathContext();
  const { saveNewPath, loading: savingNewPath } = useSaveNewPath();
  const { savePath, loading } = useSavePath();

  const onSave = async () => {
    try {
      await savePath();
    } catch {
      message.error('Draft not saved. Check connection and try again.');
    }
  };

  if (loading || savingNewPath)
    return (
      <span>
        <SaveLabel>
          Saving <Icon type="loading" spin title="saving spinner icon" />
        </SaveLabel>
      </span>
    );
  if (isNewPath)
    return (
      <SavePathButton ghost onClick={() => saveNewPath(path)}>
        Save Draft
      </SavePathButton>
    );

  if (isSaved)
    return (
      <span>
        <CheckIcon title="check icon" /> <SaveLabel>Draft Saved</SaveLabel>
      </span>
    );

  return (
    <SavePathButton ghost onClick={onSave}>
      Save Changes
    </SavePathButton>
  );
};

const SaveLabel = styled.span`
  &&& {
    color: ${colours.darkGrey50};
    font-weight: 600;
    margin-left: 0;
    font-size: 14px;
  }
`;

const SavePathButton = styled(Button)`
  &&& {
    color: ${colours.darkGrey50};
    border-color: ${colours.darkGrey50};
    :hover {
      background-color: ${colours.lightGrey500} !important;
    }
  }
`;

export { SaveButton };
