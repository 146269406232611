import { getCourseId } from '../hooks';
import { CustomLearningPath } from '../types/learning-path/CustomLearningPath';

const isComponentIdInPath = (path: CustomLearningPath, id: string): boolean => {
  const pathIds = path?.sections.reduce((accumulator: string[], section) => {
    const sectionCourseIds = section?.components.map((component) => getCourseId(component));
    return [...accumulator, ...sectionCourseIds];
  }, []);
  const pathIdsSet = new Set(pathIds);
  return pathIdsSet.has(id);
};

export { isComponentIdInPath };
