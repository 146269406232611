import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, colours, Flexbox, Box, message } from '@a-cloud-guru/rainbow-ui';
import { trace } from 'services/analytics';
import { MetadataTile, Skeleton, StyledSVG, SKILL_LEVEL_ICONS, SKILL_LEVEL_MAP } from 'components/common';
import { useIsComponentIdInPath, useSplit, useTotalDuration } from 'hooks';
import { formatVendorList, getCourseLessonDisplay, getCourseExtractLessonDisplay, getDurationDisplay } from 'utils';
import { CourseOverview } from 'types';
import { PROVIDER_LOOKUP } from '../../constant';
import { Section } from 'types/learning-path/Section';
import { Component } from 'types/learning-path/Component';
import {
  UPDATE_COURSE_META_DATA,
  ADD_ITEM_TO_PATH,
  DELETE_ITEM_FROM_PATH,
  REMOVE_ALL_COURSE_COMPONENTS,
  ADD_ALL_COURSE_COMPONENTS,
  usePathContext
} from 'context/PathContext';
import { UpdatedComponent } from 'types/common';

import { ReactComponent as PlusCircleSvg } from 'static/images/svg-icons/plus-circle.svg';
import { ReactComponent as ProviderSvg } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as BinSvg } from 'static/images/svg-icons/bin.svg';
import { ReactComponent as LessonSvg } from 'static/images/svg-icons/lesson.svg';
import { ReactComponent as FilterSvg } from 'static/images/svg-icons/filter.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';

const MetadataSkeletons = () => (
  <MetaDataList>
    <Skeleton mr="s3" />
    <Skeleton width="100px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
    <Skeleton width="80px" mr="s3" />
  </MetaDataList>
);

// TODO: This limit is a temporary workaround to prevent the timeout on course service.
// After the issue has been resolved by student team we should change it to some higher value
const COURSE_LIMIT = 15;
const LIMIT_MESSAGE_TIMEOUT = 10; // seconds

interface CourseMetaDataProps {
  loading?: boolean;
  courseOverview: CourseOverview;
}

const CourseMetaData: React.FC<CourseMetaDataProps> = ({ loading, courseOverview }) => {
  const [selectAllButtonType, setSelectAllButtonType] = useState<'default' | 'primary'>('default');
  const courseExtractSplit = useSplit('DEV_COURSE-EXTRACT_ENABLED');
  const isIdComponentIdInPath = useIsComponentIdInPath();
  const {
    state: {
      path: { id: pathId, sections },
      selectedItem
    },
    dispatch
  } = usePathContext();
  const itemType = selectedItem?.itemType;

  const courseId = courseOverview?.id;
  const isInPath = !!courseId && isIdComponentIdInPath(courseId);

  const courseCount = useMemo(
    () => sections.reduce((totalCount: number, section: Section) => totalCount + section.components.length, 0),
    [sections]
  );
  const skillLevel =
    courseOverview?.skillLevels.length > 0 ? SKILL_LEVEL_MAP[courseOverview.skillLevels[0].toLowerCase()] : '';
  const vendor = useMemo(() => courseOverview?.vendors[0]?.name || '', [courseOverview]);
  const numberOfLessons = courseOverview?.numberOfLessons;
  const shortSummary = courseOverview?.shortSummary;
  const duration = useTotalDuration();
  // calculate lesson count and duration
  const findComponentIndex =
    sections.length > 0
      ? sections[0].components.findIndex((component: Component) => {
          if (component.type === 'course' && component.id === courseId) {
            return component;
          } else if (component.type === 'clp_course_extract' && component.courseId === courseId) {
            return component;
          }
        })
      : -1;
  const numExtractComponentIds =
    findComponentIndex >= 0 ? sections[0].components[findComponentIndex].extractComponentIds?.length : -1;

  const numberOfSelectedLessons = findComponentIndex >= 0 ? numExtractComponentIds : 0;
  const isCourseExtract = selectedItem?.itemType === 'clp_course_extract';
  const lessonsDisplay = !isCourseExtract
    ? getCourseLessonDisplay(numberOfLessons, courseExtractSplit)
    : getCourseExtractLessonDisplay(numberOfLessons, numberOfSelectedLessons);

  const getDuration =
    findComponentIndex >= 0
      ? sections[0].components[findComponentIndex].computedDuration
      : courseOverview && courseOverview.computedDuration;
  const computedDuration = getDuration !== null ? getDurationDisplay(getDuration) : null;

  useEffect(() => {
    if (courseOverview && isInPath) {
      const updatedComponent: UpdatedComponent = {
        id: courseOverview.id,
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        duration: courseOverview.computedDuration,
        computedDuration: courseOverview.computedDuration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)]
      };
      dispatch({
        type: UPDATE_COURSE_META_DATA,
        updatedComponent: updatedComponent
      });
    }
  }, [isInPath, courseOverview, dispatch]);

  useEffect(() => {
    if (itemType === 'clp_course_extract' && numExtractComponentIds === 0) {
      setSelectAllButtonType('primary');
    } else if (itemType === 'clp_course_extract' && numExtractComponentIds && numExtractComponentIds >= 0) {
      setSelectAllButtonType('default');
    }
  }, [numExtractComponentIds, itemType]);

  const addToPathHandler = useCallback(() => {
    if (!loading && courseOverview) {
      if (courseCount >= COURSE_LIMIT) {
        const getHours = (seconds: number) => Math.floor(seconds / 3600);
        message.warning(
          `Path duration is over ${getHours(duration)} hours and contains the maximum ${COURSE_LIMIT} courses.`,
          LIMIT_MESSAGE_TIMEOUT
        );
        return;
      }

      const newItem: Component = {
        id: courseOverview.id,
        courseId: courseOverview.id,
        type: 'course',
        title: courseOverview.title,
        numberOfLessons: courseOverview.numberOfLessons,
        duration: courseOverview.computedDuration,
        computedDuration: courseOverview.computedDuration,
        artworkUrl: courseOverview.artworkUrl,
        skillLevels: courseOverview.skillLevels ?? [],
        vendors: [formatVendorList(courseOverview.vendors)]
      };
      const courseId = newItem.id;
      const courseType = newItem.type;

      trace.track('Item added to Path', { pathId, courseId, courseType });

      dispatch({
        type: ADD_ITEM_TO_PATH,
        newItem: newItem,
        isSaved: false
      });
    }
  }, [loading, pathId, courseCount, courseOverview, duration, dispatch]);

  type AddRemoveCourseButtonProps = {
    isInPath: boolean;
  };

  type SelectUnselectAllCourseComponentsButtonProps = {
    isInPath: boolean;
  };

  //to remove when course extracts are released
  const AddRemoveCourseButton: React.FC<AddRemoveCourseButtonProps> = ({ isInPath }) => {
    const deleteFromPathHandler = useCallback(() => {
      if (courseOverview) {
        trace.track('Item removed from Path', { pathId, courseId: courseOverview.id });
        dispatch({
          type: DELETE_ITEM_FROM_PATH,
          componentId: courseOverview.id,
          isSaved: false
        });
      }
    }, []);
    if (courseOverview && isInPath) {
      return (
        <AddCourseButton onClick={deleteFromPathHandler} type="danger">
          Remove Course <StyledIcon svgComponent={<BinSvg />} role="img" description="Remove course from path" />
        </AddCourseButton>
      );
    }

    return (
      <AddCourseButton onClick={addToPathHandler} type="primary" data-cy="pb-add-course-button">
        Add Course <StyledIcon svgComponent={<PlusCircleSvg />} role="img" title="Add course to path" />
      </AddCourseButton>
    );
  };

  const SelectUnselectAllCourseComponentsButton: React.FC<SelectUnselectAllCourseComponentsButtonProps> = ({
    isInPath
  }) => {
    const unselectAllCourseComponents = useCallback(() => {
      trace.track('Unselected all course components', { pathId, courseId });
      dispatch({ type: REMOVE_ALL_COURSE_COMPONENTS, courseId });
    }, []);
    const selectAllCourseComponents = useCallback(() => {
      trace.track('Selected all course components', { pathId, courseId });
      dispatch({ type: ADD_ALL_COURSE_COMPONENTS, courseId, totalCourseDuration: courseOverview.computedDuration });
    }, []);
    if (!isInPath) {
      return (
        <StyledButton onClick={addToPathHandler} type="primary" data-cy="pb-add-course-button">
          Select All
        </StyledButton>
      );
    }
    if (itemType === 'course') {
      return (
        <StyledButton onClick={unselectAllCourseComponents} type="default">
          Unselect All
        </StyledButton>
      );
    }
    if (itemType === 'clp_course_extract') {
      return (
        <StyledButton onClick={selectAllCourseComponents} type={selectAllButtonType}>
          Select All
        </StyledButton>
      );
    }
    return null;
  };

  const provider = PROVIDER_LOOKUP[vendor];
  const MetadataTiles = () => (
    <MetaDataList>
      {provider && (
        <MetaDataListItem iconComponent={<ProviderSvg />} description="Provider">
          {provider}
        </MetaDataListItem>
      )}
      {skillLevel && (
        <MetaDataListItem iconComponent={SKILL_LEVEL_ICONS[skillLevel.toLowerCase()]} description="Skill level">
          <StyledDescription>{skillLevel}</StyledDescription>
        </MetaDataListItem>
      )}
      <MetaDataListItem iconComponent={isCourseExtract ? <FilterSvg /> : <LessonSvg />} description="Number of lessons">
        {lessonsDisplay}
      </MetaDataListItem>
      {computedDuration && (
        <MetaDataListItem iconComponent={<DurationIcon />} description="Duration">
          {computedDuration}
        </MetaDataListItem>
      )}
    </MetaDataList>
  );

  return (
    <MetaDataContainer>
      {loading ? (
        <Skeleton height="36px" width="140px" bgColor={colours.darkGrey400} />
      ) : courseExtractSplit && courseOverview ? (
        <SelectUnselectAllCourseComponentsButton isInPath={isInPath} />
      ) : !courseExtractSplit ? (
        <AddRemoveCourseButton isInPath={isInPath} />
      ) : null}
      {loading ? <MetadataSkeletons /> : <MetadataTiles />}
      {loading ? (
        <>
          <Skeleton width="100%" bgColor={colours.darkGrey400} mb="s3" />
          <Skeleton width="30%" bgColor={colours.darkGrey400} mb="s4" />
        </>
      ) : (
        <SummaryText>{shortSummary}</SummaryText>
      )}
    </MetaDataContainer>
  );
};

const MetaDataContainer = styled(Box)`
  padding: ${({ theme }) => `${theme.space.s3} ${theme.space.s2}`};
`;

//to remove when course extracts are released
const AddCourseButton = styled(Button)`
  display: flex;
  font-weight: 600;
  align-items: center;
  span {
    margin-right: 8px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  font-weight: 600;
  align-items: center;
`;

const StyledDescription = styled.span`
  text-transform: capitalize;
`;

const MetaDataList = styled(Flexbox)`
  align-items: center;
  color: ${colours.navy900};
  padding: ${({ theme }) => theme.space.s5} 0;
`;

const MetaDataListItem = styled(MetadataTile)`
  color: ${colours.navy900};
`;

const SummaryText = styled.p`
  color: ${colours.navy900};
`;

const StyledIcon = styled(StyledSVG)`
  path {
    fill: ${colours.white};
  }
`;

export { CourseMetaData, formatVendorList };
