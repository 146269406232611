export default {
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_LEARN_URL: 'https://staging-learn.acloud.guru',
  ACG_BUSINESS_URL: 'https://staging-business.acloud.guru',
  ALGOLIA_SEARCH_ONLY_API_KEY: '244df520f0ab19af76ed4ae40ce9a1ef',
  GLOBAL_SEARCH_ALGOLIA_INDEX: 'test_global_search',
  ORG_USERS_ALGOLIA_INDEX: 'private_test_all_organisation_users',
  AUTH0_CLIENT_ID: 'B4znkWVa0obKUsgVz14U7xVnAdfBs9wi',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  RELEASE_STAGE: 'staging',
  PRINT_TRACE_LOGS: true,
  SEGMENT_API_KEY: 'OPA1FqqE15divNW8BIWHRPX85hLIabDc'
};
