import React from 'react';
import styled from 'styled-components';
import { Box, colours, Icon, message } from '@a-cloud-guru/rainbow-ui';

import { ConfirmModal, Loading } from 'components/common';
import { getConfig } from 'config/get-config';
import { trace } from 'services/analytics';
import { useGetNumberOfOrganisationLearningPathUsers, useDeletePath } from 'hooks';

const { ACG_BUSINESS_URL } = getConfig();

interface DeleteActionProps {
  learningPathId: string;
}

const DeleteAction: React.FC<DeleteActionProps> = ({ learningPathId }) => {
  const { data: totalStudents, loading } = useGetNumberOfOrganisationLearningPathUsers({ learningPathId });
  const deletePath = useDeletePath();

  const getTitle = () => {
    if (loading || totalStudents == null) {
      return null;
    }
    return (
      <Box>
        <strong>Are you sure you want to delete this path?</strong>
      </Box>
    );
  };

  const getContent = () => {
    if (loading || totalStudents == null) {
      return <Loading />;
    }
    if (totalStudents <= 0) {
      return null;
    }
    return (
      <Box>
        You have
        <StudentCount>
          {totalStudents} {totalStudents === 1 ? 'person' : 'people'} assigned.
        </StudentCount>
        Deleting it will mean they cannot continue on the path. All course progress will remain.
      </Box>
    );
  };

  return (
    <StyledConfirmModal
      title={getTitle()}
      content={getContent()}
      loading={loading}
      data={totalStudents}
      okText="Delete path"
      cancelText="Cancel"
      autoFocusButton={null}
      okButtonProps={{ disabled: loading || totalStudents == null }}
      icon={<Icon type="exclamation-circle" theme="filled" style={{ color: colours.red }} />}
      onOk={async () => {
        trace.track('Delete Path', { pathId: learningPathId });
        try {
          await deletePath(learningPathId);
          window.location.replace(`${ACG_BUSINESS_URL}/learning-paths`);
        } catch {
          message.error('Path not deleted. Check connection and try again.');
          throw new Error();
        }
      }}
    >
      <SecondaryActionsMenuItemAnchor title="Delete path">Delete path</SecondaryActionsMenuItemAnchor>
    </StyledConfirmModal>
  );
};

export { DeleteAction };

const okButtonBackgroundColour = colours.red;
const cancelButtonTextDefaultColour = colours.greyText;
const cancelButtonBackgroundDefaultColour = colours.lightGrey300;
const cancelButtonTextHoverColour = colours.blue500;
const cancelButtonBackgroundHoverColour = colours.lightGrey500;

const StudentCount = styled.strong`
  margin: 0 3px;
`;

const StyledConfirmModal = styled(ConfirmModal)`
  .ant-modal-confirm-btns > button:nth-child(1) {
    color: ${cancelButtonTextDefaultColour};
    background-color: ${cancelButtonBackgroundDefaultColour};
    border-color: ${cancelButtonBackgroundDefaultColour};
  }
  .ant-modal-confirm-btns > button:nth-child(1):hover {
    color: ${cancelButtonTextHoverColour};
    background-color: ${cancelButtonBackgroundHoverColour};
    border-color: ${cancelButtonBackgroundHoverColour};
  }
  .ant-modal-confirm-btns > button:nth-child(2) {
    background-color: ${okButtonBackgroundColour};
    border-color: ${okButtonBackgroundColour};
  }
`;

const SecondaryActionsMenuItemAnchor = styled.a`
  && {
    color: ${colours.navy900};
  }
`;
