export const PROVIDER_LOOKUP: Record<string, string> = {
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'GCP',
  linux: 'Linux',
  hashicorp: 'HashiCorp',
  python: 'Python',
  'cloud-native': 'Cloud Native',
  kubernetes: 'Cloud Native',
  'red-hat': 'Red Hat',
  comptia: 'CompTIA',
  suse: 'SUSE',
  microsoft: 'Microsoft',
  confluent: 'Confluent',
  apache: 'Apache',
  ansible: 'Ansible',
  elastic: 'Elastic',
  docker: 'Docker'
};

export const ALGOLIA_SEARCH_HIT_TYPE: Record<string, string> = {
  COURSE: 'COURSE',
  ACG_HANDS_ON_LAB: 'HANDS-ON LAB',
  PRACTICE_EXAM: 'PRACTICE EXAM'
};

export const ALGOLIA_LEARN_TYPE_UI_MAP: Record<string, string> = {
  COURSE: 'Courses',
  ACG_HANDS_ON_LAB: 'Hands-on Labs',
  PRACTICE_EXAM: 'Practice Exams'
};
export const NEW_PATH_ID = 'new-path';
export const MAX_PATH_NAME_LENGTH = 120;
export const PATH_NAME_WARNING_LIMIT = 12;
export const MAX_PATH_DESCRIPTION_LENGTH = 1500;
export const PATH_DESCRIPTION_WARNING_LIMIT = 500;
export const ARTWORK_SOURCE_WIDTH = '80';
