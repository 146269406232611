import { useEffect, useRef } from 'react';
import { Flexbox, colours } from '@a-cloud-guru/rainbow-ui';

import styled from 'styled-components';
import { ScrollableArea } from 'components/common';
import { CourseDetails } from './CourseDetails';
import { LabDetails } from './LabDetails';
import { usePathContext } from 'context/PathContext';

const DetailsPanel: React.FC = () => {
  const {
    state: { selectedItem }
  } = usePathContext();

  const panelTopRef = useRef<HTMLDivElement>(null);
  const itemType = selectedItem?.itemType;

  useEffect(() => {
    if (panelTopRef.current?.scrollIntoView) {
      panelTopRef.current.scrollIntoView();
    }
  }, [selectedItem?.courseId]);

  return (
    <PanelContainer data-cy="pb-details-panel">
      <ScrollableArea>
        <TopMarker ref={panelTopRef} />
        {(itemType === 'COURSE' || itemType === 'course' || itemType === 'clp_course_extract') && (
          <CourseDetails courseId={selectedItem?.courseId} courseType={itemType} />
        )}
        {itemType === 'ACG_HANDS_ON_LAB' && <LabDetails labId={selectedItem?.itemId} />}
      </ScrollableArea>
    </PanelContainer>
  );
};

const PanelContainer = styled(Flexbox)`
  flex-direction: column;
  flex: 1;
  background: ${colours.white};
  box-shadow: 0px 9px 15px rgba(7, 1, 82, 0.12);
`;

const TopMarker = styled.div`
  visibility: hidden;
`;

export { DetailsPanel };
