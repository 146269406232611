import { ApolloError, useQuery } from '@apollo/react-hooks';

import { CUSTOM_LEARNING_PATH_QUERY } from 'data';

interface UseGetCustomLearningPath {
  data: any;
  loading: boolean;
  error?: ApolloError;
}

// TODO: Refactor with GQL CodeGen + useLazyQuery!
const useGetCustomLearningPath = ({ id }: { id: string | undefined }): UseGetCustomLearningPath => {
  const { data, loading, error } = useQuery(CUSTOM_LEARNING_PATH_QUERY, {
    variables: {
      id
    },
    skip: !id
  });

  return {
    data: data?.customLearningPath,
    loading,
    error
  };
};

export { useGetCustomLearningPath, CUSTOM_LEARNING_PATH_QUERY };
