import React from 'react';
import styled from 'styled-components';
import { Box, Card, Flexbox, colours, Button } from '@a-cloud-guru/rainbow-ui';
import { MetadataTile, SKILL_LEVEL_ICONS, SKILL_LEVEL_MAP } from 'components/common';
import { Heading3 } from 'components/typography';
import { ReactComponent as LessonIcon } from 'static/images/svg-icons/lesson.svg';
import { ReactComponent as DurationIcon } from 'static/images/svg-icons/icon-duration.svg';
import { ReactComponent as FilterIcon } from 'static/images/svg-icons/filter.svg';
import { ReactComponent as UnlinkSvg } from 'static/images/svg-icons/unlink.svg';
import { ReactComponent as ProviderIcon } from 'static/images/svg-icons/icon-provider.svg';
import { ReactComponent as BinSvg } from 'static/images/svg-icons/bin.svg';
import { getDurationDisplay, getCourseExtractLessonDisplay, getCourseLessonDisplay } from 'utils';
import { Component } from 'types';
import { rgba } from 'polished';
import { useSplit } from 'hooks';

interface PathComponentProps {
  index: number;
  component: Component;
  deleteItemFromPath: (componentId: string) => void;
  isDragging: boolean;
  isItemSelected: boolean;
  artworkUrl: string;
  bgColor: string;
  onClick: () => void;
}

const PathComponent: React.FC<PathComponentProps> = ({
  index,
  component,
  deleteItemFromPath,
  isDragging,
  isItemSelected,
  artworkUrl,
  bgColor,
  onClick
}) => {
  // split flag to display checkboxes for course extracts
  const courseExtractSplit = useSplit('DEV_COURSE-EXTRACT_ENABLED');
  const { title, computedDuration, numberOfLessons, vendors, skillLevels, type } = component;
  const duration = computedDuration !== null ? getDurationDisplay(computedDuration) : null;
  const mapSkillLevel = SKILL_LEVEL_MAP[skillLevels[0]?.toLowerCase()];
  const skillLevel = mapSkillLevel ? mapSkillLevel : skillLevels.length > 0 ? skillLevels[0] : '';
  const skillIcon = skillLevel.toLowerCase();
  const vendor = vendors[0] || '';

  const isCourseExtract = type === 'clp_course_extract' && 'extractComponentIds' in component;
  const numberOfSelectedLessons = component?.extractComponentIds?.length;

  const lessonsDisplay = isCourseExtract
    ? getCourseExtractLessonDisplay(numberOfLessons, numberOfSelectedLessons)
    : courseExtractSplit
    ? null
    : getCourseLessonDisplay(numberOfLessons, courseExtractSplit);

  return (
    <CourseBox data-cy={`pb-path-component-${index}`} onClick={onClick}>
      <ComponentCard
        bodyStyle={{ padding: '18px', background: isItemSelected ? colours.lightGrey : '' }}
        hoverable
        $isDragging={isDragging}
      >
        <Flexbox mb="14px">
          <Box position="relative">
            <ImageContainer isSolid={false} bgColor={bgColor ?? colours.white} />
            <Artwork src={artworkUrl} alt={title} />
            {isCourseExtract && <UnlinkIcon />}
          </Box>
          <Flexbox ml="10px" justifyContent="space-between" width="80%">
            <CourseTitle>{title}</CourseTitle>
            <DeleteButton
              data-cy={`pb-path-component-${index}-cta-delete`}
              type="link"
              onClick={(event) => {
                event.stopPropagation();
                deleteItemFromPath(component.id);
              }}
            >
              <BinSvg role="img" title="Remove course from path" />
            </DeleteButton>
          </Flexbox>
        </Flexbox>
        <Flexbox alignItems="center">
          {!courseExtractSplit && vendor !== '' && (
            <MetadataTile iconComponent={<ProviderIcon />} description="Cloud provider">
              {vendor}
            </MetadataTile>
          )}
          <Flexbox marginRight="auto">
            {skillLevel !== '' && (
              <MetadataTile iconComponent={SKILL_LEVEL_ICONS[skillIcon]} description="Skill level">
                <StyledSkillLevel>{skillLevel}</StyledSkillLevel>
              </MetadataTile>
            )}
          </Flexbox>
          <Flexbox>
            {isCourseExtract && (
              <MetadataTile iconComponent={<FilterIcon />} description="Number of lessons">
                {lessonsDisplay}
              </MetadataTile>
            )}
            {!courseExtractSplit && !isCourseExtract && (
              <MetadataTile iconComponent={<LessonIcon />} description="Number of lessons">
                {lessonsDisplay}
              </MetadataTile>
            )}
          </Flexbox>
          <Flexbox marginLeft="auto">
            <MetadataTile iconComponent={<DurationIcon />} description="Total duration">
              {duration}
            </MetadataTile>
          </Flexbox>
        </Flexbox>
      </ComponentCard>
    </CourseBox>
  );
};

type ComponentCardProps = {
  $isDragging: boolean;
};

const ComponentCard = styled(Card)<ComponentCardProps>`
  border-radius: 4px;
  box-shadow: ${($isDragging) => ($isDragging ? '0px 7px 15px rgba(7, 1, 82, 0.09)' : 'none')};
`;

interface styledImageContainerProps {
  bgColor: string;
  isSolid: boolean;
}
const ImageContainer = styled.div<styledImageContainerProps>`
  background-color: ${({ bgColor, isSolid }) => (bgColor && isSolid ? bgColor : rgba(bgColor, 0.15))};
  border-radius: 2px;
  width: 89px;
  height: 50px;
`;

const CourseTitle = styled(Heading3)`
  color: ${colours.navy900};
  font-weight: 700;
`;

const CourseBox = styled(Box)`
  margin-bottom: 10px;
  width: 100%;
`;

const Artwork = styled.img`
  width: 57px;
  height: 57px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  right: 0;
  top: -3px;
  margin-left: auto;
  margin-right: auto;
`;

const UnlinkIcon = styled(UnlinkSvg)`
  position: absolute;
  left: 25%;
  top: 6px;
`;

const DeleteButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
    transition: none;
  }
  :hover {
    * > path {
      fill: ${colours.red700};
    }
  }
`;

const StyledSkillLevel = styled.span`
  text-transform: capitalize;
`;

export { PathComponent };
