import gql from 'graphql-tag';

const CUSTOM_LEARNING_PATH_QUERY = gql`
  query PathBuilder_customLearningPath($id: ID!) {
    customLearningPath(id: $id) {
      id
      title
      description
      sections {
        components {
          id
          type
          ... on CustomLearningPathACGCourseComponent {
            title
            numberOfLessons
            computedDuration
            artworkUrl
            duration
            vendors
            skillLevels
          }
          ... on CustomLearningPathCourseExtractComponent {
            courseId
            extractComponentIds
            title
            numberOfLessons
            computedDuration
            artworkUrl
            duration
            vendors
            skillLevels
          }
        }
      }
      updatedAt
      publishedAt
      isReadOnly
    }
  }
`;

const NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS = gql`
  query PathBuilder_numberOfOrganisationLearningPathUsers($learningPathId: ID!) {
    numberOfOrganisationLearningPathUsers(learningPathId: $learningPathId)
  }
`;

export { CUSTOM_LEARNING_PATH_QUERY, NUMBER_OF_ORGANISATION_LEARNING_PATH_USERS };
