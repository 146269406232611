import { useMutation, MutationResult, FetchResult } from '@apollo/react-hooks';
import { CUSTOM_LEARNING_PATH_CREATE } from 'data';
import { CustomLearningPath, SectionsLite } from 'types';

type CreatePath = Pick<CustomLearningPath, 'title' | 'description'> & SectionsLite;
interface UseCreatePath {
  results: MutationResult<any>;
  createPath: (props: CreatePath) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

// TODO: Refactor with GQL CodeGen
const useCreatePath = (): UseCreatePath => {
  const [mutate, results] = useMutation(CUSTOM_LEARNING_PATH_CREATE);

  return {
    results,
    createPath: ({ title, description, sections }: CreatePath) => {
      const newPath = mutate({
        variables: {
          input: { title, description, sections }
        }
      });

      return newPath;
    }
  };
};

export { useCreatePath };
