import { ApolloError, useQuery } from '@apollo/react-hooks';
import { makeFeatureHasAccessLevel, featureAccessLevels } from '@a-cloud-guru/packaging-feature-access';
import get from 'lodash/get';

import { PACKAGING_USER_FEATURE_ACCESS } from 'data';

interface FeatureAccess {
  hasAccess: boolean;
  loading: boolean;
  error?: ApolloError;
}

const useFeatureAccess = (feature: string): FeatureAccess => {
  const { data, loading, error } = useQuery(PACKAGING_USER_FEATURE_ACCESS);

  const userFeatureAccess = get(data, 'Packaging_userFeatureAccess.userFeatureAccess');

  const featureHasAccessLevel = makeFeatureHasAccessLevel(userFeatureAccess);

  return {
    hasAccess: featureHasAccessLevel(feature)(featureAccessLevels.ENABLED),
    loading,
    error
  };
};

export { useFeatureAccess };
