import Joi from 'joi';

const schema = Joi.object()
  .keys({
    ACG_ENV: Joi.string().required(),
    ACG_DOMAIN: Joi.string(),
    ACG_LEARN_URL: Joi.string(),
    ACG_BUSINESS_URL: Joi.string(),
    ALGOLIA_APP_ID: Joi.string(),
    ALGOLIA_SEARCH_ONLY_API_KEY: Joi.string(),
    GLOBAL_SEARCH_ALGOLIA_INDEX: Joi.string(),
    ORG_USERS_ALGOLIA_INDEX: Joi.string(),
    AUTH0_CLIENT_ID: Joi.string(),
    AUTH0_DOMAIN: Joi.string(),
    AUTH0_CUSTOM_DOMAIN: Joi.string(),
    AUTH0_API_AUDIENCE: Joi.string(),
    GRAPHQL_API_ENDPOINT: Joi.string().required(),
    CLOUDINARY_ACCOUNT: Joi.string().required(),
    SPLIT_IO_API_KEY: Joi.string().required(),
    CLOUDINARY_BASE_URL: Joi.string(),
    VERSION: Joi.string(),
    BUGSNAG_API_KEY: Joi.string(),
    RELEASE_STAGE: Joi.string(),
    PRINT_TRACE_LOGS: Joi.boolean(),
    SEGMENT_API_KEY: Joi.string()
  })
  .required();

export { schema };
