import styled from 'styled-components';
import { Modal, Button, Alert, colours, message } from '@a-cloud-guru/rainbow-ui';
import { useState } from 'react';
import { getConfig } from 'config/get-config';
import { useSavePath } from 'hooks';

const { ACG_BUSINESS_URL } = getConfig();

interface UpdateModalProps {
  isUpdateModalVisible: boolean;
  hideUpdateModal: () => void;
  learningPathId: string;
}

export const UpdateModal: React.FC<UpdateModalProps> = ({ isUpdateModalVisible, hideUpdateModal, learningPathId }) => {
  const [publishUpdatesButtonLoading, setPublishUpdatesLoading] = useState<boolean>(false);
  const { savePath } = useSavePath();

  const publishUpdates = async () => {
    setPublishUpdatesLoading(true);
    try {
      await savePath();
      window.location.assign(`${ACG_BUSINESS_URL}/learning-path?id=${learningPathId}`);
    } catch {
      message.error('Updates not published. Check connection and try again.');
    }
    setPublishUpdatesLoading(false);
  };

  const publishUpdatesButton = (
    <Button type="primary" onClick={publishUpdates} loading={publishUpdatesButtonLoading}>
      Publish updates
    </Button>
  );

  return (
    <StyledModal
      title="Update Learning Path"
      visible={isUpdateModalVisible}
      onCancel={hideUpdateModal}
      footer={publishUpdatesButton}
    >
      <StyledAlert
        message="Changes cannot be saved without re-publishing."
        description="These will be reflected immediately in the learner experience and may affect progress reports for users."
        type="warning"
        showIcon
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 500px;
  }
  .ant-modal-body {
    padding: 0 30px;
  }
  .ant-modal-header {
    border-bottom-width: 0;
    padding: 30px 30px 23px;
  }
  .ant-modal-footer {
    border-top-width: 0;
    padding: 20px 30px 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 21px;
    color: ${colours.navy900};
  }
  .ant-modal-close {
    margin-top: 15px;
    margin-right: 15px;
    color: ${colours.darkGrey700};
  }
  color: ${colours.darkGrey900};
`;

const StyledAlert = styled(Alert)`
  .ant-alert-message {
    color: ${colours.darkGrey900};
  }
  color: ${colours.darkGrey900};
`;
