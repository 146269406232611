import { Section, SectionLite } from 'types';

const stripSectionsForUpdate = (sections: Array<Section>): Array<SectionLite> =>
  sections.length !== 0
    ? sections.map((section) => ({
        components: section.components.map((component) => ({
          id: component.id,
          type: component.type,
          ...(component?.courseId && { courseId: component.courseId }),
          ...(component?.extractComponentIds && { extractComponentIds: component.extractComponentIds })
        }))
      }))
    : [{ components: [] }];

export { stripSectionsForUpdate };
