import { SplitIOProvider } from 'splitio-react';
import { getConfig } from 'config/get-config';
import { useAuth } from '@a-cloud-guru/react-auth';

const { SPLIT_IO_API_KEY } = getConfig();

const SPLITS: Array<string> = ['CLP_BANNER', 'DEV_COURSE-EXTRACT_ENABLED', 'CLP_STANDALONE_LABS'];

interface SplitIOProps {
  children: JSX.Element;
}
const SplitIO: React.FC<SplitIOProps> = ({ children }) => {
  const { viewer, error } = useAuth();

  if (SPLITS.length === 0) {
    return children;
  }

  if (error) {
    console.error('splitio-error', error);
  } //TODO: what do do on error?

  const { id: userId, organisationId } = viewer || {};

  return (
    <SplitIOProvider
      splitKey={userId}
      authorizationKey={SPLIT_IO_API_KEY}
      attributes={{
        userId,
        organisationId
      }}
      splits={[...SPLITS]}
    >
      {children}
    </SplitIOProvider>
  );
};

export { SplitIO as SplitIOProvider };
