const contentTypes = {
  HANDS_ON_LAB: 'hands-on-lab',
  QUIZ: 'quiz',
  COURSE_VIDEO: 'video',
  PRACTICE_EXAM: 'practiceExam',
  EPISODE: 'episode'
};

const tagDisplayMap = {
  [contentTypes.HANDS_ON_LAB]: 'Hands-on Lab',
  [contentTypes.PRACTICE_EXAM]: 'Practice Exam',
  [contentTypes.QUIZ]: 'Quiz'
};

export { contentTypes, tagDisplayMap };
