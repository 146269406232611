import styled from 'styled-components';
import { Box, colours, Button, breakpoints } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as RightArrowSvg } from 'static/images/svg-icons/arrow-right.svg';
import { ReactComponent as LabWithGradientIcon } from 'static/images/svg-icons/lab-with-gradient.svg';
import { makeBuildOptimizedSrc } from 'components/common/image';
import { Heading2 } from 'components/typography';
import { SET_SELECTED_ITEM, usePathContext } from 'context/PathContext';

const LabMastHead: React.FC = () => {
  const wallpaperUrl =
    'https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1604294943760-wallpaper-17-srgb.jpg';
  const desktopSrc = makeBuildOptimizedSrc({ width: '1000', format: 'auto' })(wallpaperUrl);
  const mobileSrc = makeBuildOptimizedSrc({ width: '800', crop: 'fill', format: 'auto' })(wallpaperUrl);
  const backgroundColour = colours.navy800;
  const {
    state: { selectedItem },
    dispatch
  } = usePathContext();

  return (
    <LabMastHeadContainer
      desktopSrc={desktopSrc}
      mobileSrc={mobileSrc}
      backgroundColour={backgroundColour}
      onClick={() => dispatch({ type: SET_SELECTED_ITEM, newItem: null })}
    >
      <Box opacity="1">
        <CloseButton type="link">
          <RightArrowSvg title="Close preview" />
        </CloseButton>
        <TitleContainer>
          <LabIcon title="Hands-on Lab" />
          <Title>{selectedItem?.title}</Title>
        </TitleContainer>
      </Box>
    </LabMastHeadContainer>
  );
};

const CloseButton = styled(Button)`
  &&& {
    padding: 0;
    border: none;
    display: flex;
  }
`;

const LabMastHeadContainer = styled(Box)`
  padding: ${({ theme }) => theme.space.s8};
  background: ${({ desktopSrc, backgroundColour }) => `url(${desktopSrc}) center no-repeat, ${backgroundColour}`};
  @media (max-width: ${breakpoints.md}) {
    background: ${({ mobileSrc, backgroundColour }) => `url(${mobileSrc}) center no-repeat, ${backgroundColour}`};
  }
  transition: all 0.1s ease-in-out;

  :hover {
    cursor: pointer;

    ${CloseButton} {
      transform: translateX(10px);
    }
  }
`;

const TitleContainer = styled(Box)`
  display: flex;
  margin: ${({ theme }) => theme.space.s5} 0;
`;

const LabIcon = styled(LabWithGradientIcon)`
  width: 64px;
  height: 64px;
  margin-right: 20px;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const Title = styled(Heading2)`
  font-family: 'GT Walsheim';
  color: ${colours.white};
  width: 70%;
  margin: auto 0;
`;

export { LabMastHead };
